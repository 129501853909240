import React from 'react';
import SEO from '../components/seo';
import { Container, H1, H2, Ol, P } from '../styles/styles';
import { GlobalStyle } from '../styles/global';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'gatsby';

const privacy = () => {
  return (
    <React.Fragment>
      <SEO title="Privacy Policy" />
      <GlobalStyle />
      <Header />
      <Container style={{ maxWidth: '600px', marginBottom: '100px' }}>
        <div style={{ marginTop: '100px', marginBottom: '32px' }}>
          <H1>Privacy Policy</H1>
          <H2 style={{ marginTop: '12px' }}>What information we collect and why</H2>
        </div>
        <P>
          This policy applies to the site/service/forum ("platform") owned and operated by us, at Design Lobby, accessible from <Link to="/">Design Lobby</Link>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Design Lobby and how we use it.
        </P>
        <br />
        <P>
          If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at <span>hello@designlobby.app</span>.
        </P>
        <P>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Design Lobby. This policy is not applicable to any information collected offline or via channels other than this website. </P>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>What data we collect</H2>
        </div>
        <P>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</P>
        <br />
        <P>We collect the following personal data from you in connection with the platform</P>
        <br />
        <Ol>
          <li>Profile or Contact Data such as Name, Email, Passwords, we use this information to authenticate you when you log in to the platform</li>
          <li>Financial information you provide, in order to process transactions when you purchase a plan (your card information is not held by us, it is collected by our third-party payment service providers Stripe)</li>
          <li>Device/IP Data such as IP address Device ID, Type of device/operating system/browser used to access the Services via Google Analytics</li>
          <li>User Content such as images, icon,fonts, colors also other content, information,comments, and materials that you post to or through the service. Please do not provide us with any sensitive personal data whilst using the platform.</li>
        </Ol>
        <P>If you contact us directly, we may receive additional information about you such as your name, email address, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</P>
        <P>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</P>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>How we use/share your information</H2>
        </div>
        <P>We disclose your personal data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you. </P>
        <Ol>
          <li>Operate and improve the service.</li>
          <li>Hosting, technology and server providers.</li>
          <li>Communicate with users regarding support, security, technical issues, commerce, marketing, and transactions;</li>
          <li>Payment processors.</li>
          <li>Analytics providers.</li>
          <li>Support and customer service vendors.</li>
          <li>Security and fraud prevention consultants.</li>
          <li>Third Parties You Authorize, Access or Authenticate. or Other users. </li>
        </Ol>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2> Accessing, correcting and deleting your data</H2>
        </div>
        <P>
          You have the right to request a copy of some or all of your personal data — please email <span>hello@designlobby.app</span> to do so. We also want to make sure that your personal data is accurate and up-to-date, so please email us if you would like to amend or delete it.
        </P>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Log Files</H2>
        </div>
        <P>
          Design Lobby follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of
          clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
        </P>
        <P>Note that Design Lobby has no access to or control over these cookies that are used by third-party advertisers.</P>

        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Third Party Privacy Policies</H2>
        </div>
        <P>Design Lobby's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </P>
        <br />
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Changes to this Policy</H2>
        </div>
        <P>
          Please revisit this page periodically to stay aware of any changes to this olicy, which we may update from time to time. If we modify this policy, we will make it available through the service, and indicate the date of the latest revision, and will comply with applicable law. Your continued use of the service after the revised policy has become effective indicates that you have read,
          understood and agreed to the current version of the policy.{' '}
        </P>
        <br />
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Consent</H2>
        </div>
        <P>By using our website, you hereby consent to our Privacy Policy and agree to its terms. For our Terms and Conditions.</P>

        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>CCPA Privacy Rights (Do Not Sell My Personal Information)</H2>
        </div>
        <P>Under the CCPA, among other rights, California consumers have the right to:</P>
        <P>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</P>
        <P>Request that a business delete any personal data about the consumer that a business has collected.</P>
        <P>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</P>
        <P>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</P>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>GDPR Data Protection Rights</H2>
        </div>
        <P>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</P>
        <P>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</P>
        <P>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</P>
        <P>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</P>
        <P>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</P>
        <P>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</P>
        <P>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</P>
        <P>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</P>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Children's Information</H2>
        </div>
        <P>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</P>
        <P>Design Lobby does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</P>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default privacy;
